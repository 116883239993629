/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react'
import styled from 'styled-components'
import { devices } from './common/theme'
import { isSafari } from 'react-device-detect'
type VideoContainerProps = {
  src: string,
}

const Container = styled.div`
  position: relative;
  //min-height: 300px;
  overflow: hidden;

  @media ${devices.mobile} {
    //min-height: 237px;
  }
`

const ContentWrapper = styled.div`
  video, img {
    width: 100%;
    height: 100%;
  }
`

export const VideoContainer = ({ src }: VideoContainerProps) => {
  return <Container>
    <ContentWrapper>
      {isSafari ?
        <img src={src} />
        :
        <video autoPlay muted loop playsInline={true}>
          <source src={src} type="video/mp4" />
        </video>
      }
    </ContentWrapper>
  </Container>

}
