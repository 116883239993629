/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react'
import Select from 'react-select'
import styled from 'styled-components'
import { Error, Label } from './Input'

type SelectOption = {
  value: string,
  label: string,
}

type MultiselectProps = {
  label: string,
  placeholder?: string,
  options: SelectOption[],
  inputRef: any,
  onChange: (options: SelectOption[]) => void,
  values: SelectOption[],
  error?: string,
}

type SelectProps = {
  label: string,
  placeholder?: string,
  options: SelectOption[],
  inputRef: any,
  onChange: (option: SelectOption) => void,
  value: SelectOption,
  error?: string,
}

const SelectWrapper = styled.div`
  margin-bottom: 35px;
`

const getMultiSelectCustomStyles = (state: any) => ({
  control: (provided: any, state: any) => ({
    ...provided,
    ...(state.isFocused && {
      border: '1px solid #00AA78',
      boxShadow: 'none',
      filter: 'drop-shadow(0 0 0.7rem rgba(0, 170, 120, 0.5))',
    }),
    padding: '2px 0',
    marginBottom: '5px',
    boxShadow: 'none',
    '&:hover': {
      border: '1px solid #00AA78',
    },
  }),
  option: (provided: any) => ({
    ...provided,
    color: '#000',
    backgroundColor: '#fff',
    padding: 15,
    '&:hover': {
      backgroundColor: '#00AA78',
    },
  }),
  multiValue: (provided: any) => ({
    ...provided,
    backgroundColor: '#00AA7833',
    padding: 6,
    fontWeight: 700,
  }),
  clearIndicator: () => ({
    display: 'none',
  }),
  placeholder: (provided: any) => ({
    ...provided,
    paddingLeft: 15,
  }),
  multiValueRemove: (provided: any) => ({
    ...provided,
    color: 'gray',
    ':hover': {
      backgroundColor: 'gray',
      color: 'white',
    },
  }),
})

const getSelectCustomStyles = (state: any) => ({
  control: (provided: any, state: any) => ({
    ...provided,
    ...(state.isFocused && {
      border: '1px solid #00AA78',
      boxShadow: 'none',
      filter: 'drop-shadow(0 0 0.7rem rgba(0, 170, 120, 0.5))',
    }),
    padding: '4px 0',
    marginBottom: '5px',
    boxShadow: 'none',
    '&:hover': {
      border: '1px solid #00AA78',
    },
  }),
  placeholder: (provided: any) => ({
    ...provided,
    paddingLeft: 15,
  }),
  option: (provided: any) => ({
    ...provided,
    color: '#000',
    backgroundColor: '#fff',
    padding: 15,
    '&:hover': {
      backgroundColor: '#00AA78',
    },
  }),
})

export const MultiSelect = (props: MultiselectProps) => {
  const [isFocused, setIsFocused] = useState(false)
  const styles = getMultiSelectCustomStyles({ isFocused })

  const onChange = (item: any) => {
    props.onChange(item)
  }

  return (
    <SelectWrapper>
      <Label>{props.label}</Label>
      <Select
        value={props.values}
        ref={props.inputRef}
        styles={styles}
        isMulti
        options={props.options}
        onChange={onChange}
        className="select"
        isSearchable={false}
        isDisabled={false}
        isLoading={false}
        isRtl={false}
        closeMenuOnSelect={false}
        placeholder=''
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        // todo noOptionsMessage
        // noOptionsMessage={() => ''}
      />
      {props.error && <Error>{props.error}</Error>}
    </SelectWrapper>
  )
}

export const SimpleSelect = (props: SelectProps) => {
  const [isFocused, setIsFocused] = useState(false)

  const styles = getSelectCustomStyles({ isFocused })

  const onChange = (item: any) => {
    props.onChange(item)
  }

  return (
    <SelectWrapper>
      <Label>{props.label}</Label>
      <Select
        value={props.value}
        ref={props.inputRef}
        styles={styles}
        options={props.options}
        onChange={onChange}
        className="select"
        isSearchable={false}
        isDisabled={false}
        closeMenuOnSelect={true}
        placeholder={props.placeholder}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
      />
      {props.error && <Error>{props.error}</Error>}
    </SelectWrapper>
  )
}