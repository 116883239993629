/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { DefaultCard, CardInfo, CardTitle } from '../../../../components/Card'
import { Block } from '../../../../components/Block'
import { useParams, useNavigate } from 'react-router-dom'
import { SearchInput } from '../../../../components/Search'
import { GoBackButton, PrimaryButton } from '../../../../components/Button'
import { NavigationLink } from '../../../../components/Link'
import { VerticalDivider, HorisontalDivider } from '../../../../components/Divider'
import { CollectionItem, Collection as CollectionType } from '../../../../types'
import routes from '../../../../routes'
import data from '../mockData'
import { addBuyModal } from './BuyPage'

const Wrapper = styled.div`
  position: relative;
  padding-top: 270px ;
`

const Banner = styled.div<{ bgImgUrl: string }>`
  height: 424px;
  position: absolute;
  width: 99.1vw;
  top: ${props => `${props.theme.headerHeight}px`};
  left: 0;
  background-size: cover;
  background-position: center;
  background-image: ${({ bgImgUrl }) => `url(${bgImgUrl})`};
  z-index: -1;
`

const CollectionLogoWrapper = styled.div<{imgUrl: string}>`
  background-image: ${({ imgUrl }) => `url(${imgUrl})`};
  background-size: cover;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-bottom: 1rem;
`

const CollectionInfoWrapper = styled.div`
  border-radius: 10px;
  position: relative;
  height: 270px;
  width: 1233px;
  background-color: ${props => props.theme.colors.secondaryBlack};
  display: flex;
  align-items: center;
`
const CollectionInfo = styled.div`
  width: 100%;
  padding: 60px 0px;
  display: flex;
`

const CollectionLogo = styled.div`
  margin: auto;
`

const CollectionInfoInner = styled.div`
  width: 70%;
  display: flex;
  flex-direction: column;
  margin: auto 35px;
`

const CollectionInfoTitle = styled.span`
  font-size: 40px;
  font-weight: bold;
`

const CollectionInfoDescription = styled.p`
  font-size: 18px;
  opacity: 80%;
`

const CollectionItemsWrapper = styled.div`
  columns: 3;
`

const CollectionItemsPrice = styled.div`
  font-size: 24px;
`

const CollectionItemsFooter = styled.div`
  display: flex;
  justify-content: space-between;
`

type CollectionItemCardProps = {
  onBuyClick: () => void,
} & CollectionItem

export const CollectionItemCard = (props: CollectionItemCardProps) => {
  const [isLandscapeOriented, setIsLandscapeOriented] = useState<boolean | undefined>()
  const navigate = useNavigate()

  useEffect(() => {
    const img = document.createElement('img')
    img.src = props.videoUrl
    img.onload = () => {
      setIsLandscapeOriented(img.height < img.width)
    }
  })

  const onBuyClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation()
    props.onBuyClick()
  }

  const onCardClick = () => {
    navigate(`${routes.tokens}/${props.id}`)
  }

  if (isLandscapeOriented !== undefined) {
    return <DefaultCard imgUrl={props.videoUrl} onClick={onCardClick} isLandscapeOriented={isLandscapeOriented}>
      <CardTitle>{props.name}</CardTitle>
      <CardInfo>Автор: {props.author}</CardInfo>
      <HorisontalDivider />
      <CollectionItemsFooter>
        <CollectionItemsPrice>{props.price} &#x20bd;</CollectionItemsPrice>
        <PrimaryButton isSmall onClick={onBuyClick}>Купить</PrimaryButton>
      </CollectionItemsFooter>
    </DefaultCard>
  }
  // todo some loader
  return <div />
}

// todo remove
function shuffle(array: any[]) {
  array.sort(() => Math.random() - 0.5)
  return array
}

export const Collection = () => {
  const collectionId = useParams().collectionId!
  const collection = data[+collectionId]

  const items = shuffle(collection.items)

  return <>
    <Banner bgImgUrl={collection.previewImg} />
    <Wrapper>
      <GoBackButton />
      <CollectionInfoWrapper>
        <CollectionInfo>
          <CollectionLogo>
            <CollectionLogoWrapper imgUrl={collection.authorLogo} />
            <NavigationLink to='/'>{collection.author}</NavigationLink>
          </CollectionLogo>
          <VerticalDivider />
          <CollectionInfoInner>
            <CollectionInfoTitle>Коллекция: <br />{collection.name}</CollectionInfoTitle>
            <CollectionInfoDescription>{collection.description}</CollectionInfoDescription>
          </CollectionInfoInner>
        </CollectionInfo>
      </CollectionInfoWrapper>
      <Block marginTop={120} />
      <SearchInput placeholder='Найдите NFT' />
      <Block marginTop={80} />
      <CollectionItemsWrapper>
        {items.map((itemData: CollectionItem, i) => (
          <CollectionItemCard
            key={i}
            {...itemData}
            onBuyClick={() => addBuyModal(itemData)}
          />
        ))}
      </CollectionItemsWrapper>
    </Wrapper>
  </>
}
