/* eslint-disable @typescript-eslint/no-unused-vars */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react'
import styled from 'styled-components'
import { PrimaryButton, SecondaryButton } from '../../../components/Button'
import { Modal, CustomModalProps } from '../../../components/modal/Modal'
import { CollectionItem } from '../../../types'
import ModalService from '../../../components/modal/modal-service'
import { Success } from './SuccessModal'
import { useNavigate, useParams } from 'react-router-dom'
import routes from '../../../routes'
import { VideoContainer } from '../../../components/VideoContainer'
import { devices } from '../../../components/common/theme'

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  @media ${devices.mobile} {
    flex-direction: column;
    word-wrap: break-word;
  }
`

const ImgContainer = styled.div`
  margin: 0 auto;
  width: 50%;
  
  div {
    min-width: 250px;
  }

  @media ${devices.mobile} {
    width: 100%;
    max-width: 320px;
    margin-top: 2rem;
  }
`

const Content = styled.div`
  margin-left: 2rem;
  width: 100%;
  word-break: break-all;

  @media ${devices.mobile} {
    margin-left: 0;
    margin-top: 2rem;
  }
`
const ContentItem = styled.div`
  margin-bottom: .7rem;
`

const ContentItemTitle = styled.div`
  opacity: 40%;
`

export const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

type BuyModalProps = CustomModalProps & CollectionItem

type SuccessModalButtonsProps = CustomModalProps

const SuccessModalButtons = (props: SuccessModalButtonsProps) => {
  const { collectionId } = useParams()
  const navigate = useNavigate()

  const onBuyMoreClick = () => {
    if (collectionId) {
      return navigate(`${routes.collections}/${collectionId}`)
    }
    props.close()
  }

  return <Buttons>
    <SecondaryButton isSmall onClick={onBuyMoreClick}>Купить ещё NFT</SecondaryButton>
    <PrimaryButton isSmall onClick={() => navigate(routes.myCollection)}>Посмотреть мою коллекцию</PrimaryButton>
  </Buttons>
}

export const BuyModal = (props: BuyModalProps) => {

  const addSuccessModal = () => {
    ModalService.open(({ close }) => (
      <Success
        text='Скоро NFT окажется у вас в коллекции'
        close={close}
        buttonsBlock={<SuccessModalButtons close={close} />}
      />))
  }

  return (
    <Modal title={props.name} close={props.close}>
      <Wrapper>
        <ImgContainer>
          <VideoContainer src={props.videoUrl} />
        </ImgContainer>
        <Content>
          <ContentItem>
            <ContentItemTitle>Описание</ContentItemTitle>
            <div>{props.description}</div>
          </ContentItem>
          <ContentItem>
            <ContentItemTitle>Адрес создателя</ContentItemTitle>
            <div>{props.issuer}</div>
          </ContentItem>
          <ContentItem>
            <ContentItemTitle>Коллекция</ContentItemTitle>
            <div>{props.name}</div>
          </ContentItem>
          <ContentItemTitle>Адрес владельца</ContentItemTitle>
          <div>{props.owner ? props.owner : props.issuer}</div>
          <ContentItem />
          {/* <ContentItem>
            <ContentItemTitle>Стоимость</ContentItemTitle>
            <div>{props.price}</div>
          </ContentItem> */}
          {/* <ContentItem>
            <ContentItemTitle>Текущий баланс</ContentItemTitle>
            <div>0</div>
          </ContentItem> */}
          {!props.hideButton && <PrimaryButton isWide onClick={addSuccessModal}>Купить</PrimaryButton>}
        </Content>
      </Wrapper>
    </Modal>
  )
}

