import decode from 'jwt-decode'
import { TokenPair, TokenRefresherConfig } from '../types'
import { sleep } from '../utils/sleep'

export class TokenRefresher {
  private actualTokenPair: TokenPair
  private expTime: number
  private isRefreshing: boolean = false
  private refreshPromise: Promise<void> = Promise.resolve()
  private refreshAttempts: number = 0

  private readonly maxAttemptsToRefreshToken: number
  private readonly refreshBeforeExpire: number
  private readonly minTimeBeforeExpireToSendReq: number
  private readonly sleepAfterError: number

  constructor(private readonly options: TokenRefresherConfig) {
    this.actualTokenPair = { ...options.authorization }

    this.refreshBeforeExpire = options.refreshBeforeExpire || 30 * 1000
    this.maxAttemptsToRefreshToken = options.maxAttemptsToRefreshToken || 5
    this.minTimeBeforeExpireToSendReq = options.minTimeBeforeExpireToSendReq || 1000
    this.sleepAfterError = options.sleepAfterError || 1000

    this.expTime = this.getExpirationTime(this.actualTokenPair.access_token)
  }

  getAccessToken = async () => {
    if (this.expTime - this.refreshBeforeExpire < Date.now() && !this.isRefreshing) {
      this.refreshPromise = this.refreshToken()
    }

    if (this.expTime - this.minTimeBeforeExpireToSendReq < Date.now()) {
      await this.refreshPromise
    }

    return this.actualTokenPair.access_token
  }

  refreshToken = async () => {
    if (this.refreshAttempts >= this.maxAttemptsToRefreshToken) {
      this.isRefreshing = false
      return Promise.resolve()
    }
    try {
      this.isRefreshing = true
      const tokenPair = await this.options.refreshCallback(this.actualTokenPair.refresh_token)
      if (!tokenPair || !tokenPair.access_token || !tokenPair.refresh_token) {
        throw new Error(`invalid refreshCallback resp ${tokenPair}`)
      }
      this.actualTokenPair = tokenPair
      this.expTime = this.getExpirationTime(this.actualTokenPair.access_token)

      if (typeof this.options.onChangeAccessToken === 'function') {
        this.options.onChangeAccessToken(tokenPair.access_token)
      }
      this.refreshAttempts = 0
      this.isRefreshing = false
    }
    catch (err) {
      this.refreshAttempts++
      if (typeof this.options.onRefreshFailed === 'function') {
        this.options.onRefreshFailed(err)
      } else {
        // eslint-disable-next-line no-console
        console.log('[ApiTokenRefresher] Failed to refresh token', err)
      }
      await sleep(this.sleepAfterError)
      await this.refreshToken()
    }
  }

  private getExpirationTime = (accessToken: string) => {
    const { exp: expSec } = decode(accessToken) as { exp: number }
    return expSec * 1000
  }
}
