export default {
  root: '',
  create: 'create',
  myCollection: 'my-collection',
  howItWorks: 'how-it-works',
  qrCode: 'qr-code',
  collections: 'collections',
  tokens: 'tokens',
  signIn: 'sign-in',
  signUp: 'sign-up',
  confirm: 'confirm',
  recover: 'recover',
  logout: 'logout',
}