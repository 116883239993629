/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState, useCallback } from 'react'
import styled from 'styled-components'
import { Block } from '../../../../components/Block'
import { GoUpButton, PrimaryButton } from '../../../../components/Button'
import { DefaultCard, CardTitle, CardInfo, CollectionCardsWrapper, CardInner, CardsLoader } from '../../../../components/Card'
import { SearchInputWithTags } from '../../../../components/Search'
import { Collection, CollectionItem, ITag } from '../../../../types'
import routes from '../../../../routes'
import { useNavigate, useParams } from 'react-router-dom'
import ModalService from '../../../../components/modal/modal-service'
import { BuyModal } from '../../modals/BuyModal'
import { kitcut } from '../../../../utils'
import useStores from '../../../../hooks/useStores'
import Fuse from 'fuse.js'
import { devices } from '../../../../components/common/theme'
import logoRaso from '../../../../resources/images/logo-raso.png'
import noize from '../../../../resources/images/noise.png'
import throttle from 'lodash.throttle'

export const addBuyModal = (itemData: CollectionItem, hideButton?: boolean) => {
  ModalService.open(({ close }) => <BuyModal {...itemData} close={close} hideButton={hideButton} />)
}

const BuyPageWrapper = styled.div`
  position: relative;
  z-index: 1;
`

const NoiseBg = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 900px;
  background: url(${noize});
  z-index: 0;
`

const BannerImageWrapper = styled.div`
  width: 700px;
  height: 700px;
  background-size: cover;
  background-position: center;
  background-image: url(${logoRaso});
  background-repeat: no-repeat;
  position: absolute;
  top: 0%;
  left: 50%;
  image-orientation: from-image;

  @media ${devices.tablet} {
    left: 50%;
    top: 3%;
    width: 500px;
    height: 500px;
  }

  @media ${devices.tablet} and (orientation: landscape) {
    left: 45%;
    top: 0;
  } 

  @media ${devices.mobile} and (orientation: landscape){
    left: 50%;
    top: -40%;
    width: 400px;
    height: 400px;
  } 

  @media ${devices.mobile} and (orientation: portrait){
    left: 50%;
    top: -7%;
    transform: translateX(-50%);
    width: 300px;
    height: 300px;
  } 
`

const BannerWrapper = styled.div`
  position: relative;
  height: 480px;
  margin: 0 40px;

  @media ${devices.tabletAndMobile} and (orientation: landscape) {
    height: 400px;
  } 

  @media ${devices.mobile} and (orientation: landscape) {
    height: 200px;
  } 

  @media ${devices.mobile} and (orientation: portrait) {
    margin: 0;
    height: 420px;
  } 
`

const BannerInner = styled.div`
  width: 50%;
  position: absolute;
  top: 40%;
  left: 0;

  @media ${devices.tablet} {
    top: 20%;
  } 

  @media ${devices.mobile} and (orientation: landscape) {
    width: fit-content;
    top: 0;
    left: 30%;
    transform: translateX(-50%);

    display: flex;
    flex-direction: column;
    justify-content: center;
  } 

  @media ${devices.mobile} and (orientation: portrait) {
    width: fit-content;
    top: 50%;
    left: 50%;
    transform: translateX(-50%);

    display: flex;
    flex-direction: column;
    justify-content: center;
  } 
`

const BannerTitle = styled.h1`
  margin: 0 auto;
  font-size: 42px;

  @media ${devices.tablet} {
    font-size: 40px;
  } 

  @media ${devices.mobile} and (orientation: landscape)  {
    font-size: 28px;
    max-width: 300px;
  } 

  @media ${devices.mobile} and (orientation: portrait)  {
    font-size: 28px;
    text-align: center;
    max-width: 300px;
  } 
`

const BannerButtons = styled.div`
  margin: 40px auto;

  @media ${devices.mobile} and (orientation: landscape)  {
    width: 100%;
    margin: 20px auto;
  } 

  @media ${devices.mobile} and (orientation: portrait) {
    width: 100%;
    margin: 20px auto;
    display: flex;
    justify-content: center;
  } 
`

const CardLogoWrapper = styled.div<{imgUrl: string}>`
  background-image: ${({ imgUrl }) => `url(${imgUrl})`};
  background-size: cover;
  width: 59px;
  height: 62px;
  /* border-radius: 50%; */
  position: absolute;
  left: -2%;
  top: -38%;
  z-index: 2;
  transform: scale(0.8);

  @media ${devices.mobile} {
    left: -2%;
    top: -35%;
    width: 50px;
    height: 50px;
  } 
`

const Banner = () => {
  const { authStore } = useStores()
  const { isLoggedIn } = authStore
  const navigate = useNavigate()
  return <BannerWrapper>
    <BannerInner>
      <BannerTitle>Познакомиться с NFT-коллекцией &quot;Премия РАСО 2024&quot;</BannerTitle>
      <BannerButtons>
        {isLoggedIn ? <PrimaryButton onClick={() => navigate(routes.qrCode)}>Получить NFT</PrimaryButton> :
          <PrimaryButton onClick={() => navigate(routes.signIn)}>Вход для победителей</PrimaryButton>
        }
      </BannerButtons>
    </BannerInner>
    <BannerImageWrapper />
  </BannerWrapper>
}
const CollectionItemCard = (props: Collection) => {
  const navigate = useNavigate()
  const { api } = useStores()

  const onLinkClick = () => {
    navigate(`${routes.collections}/${props.id}`)
  }

  const onClickView = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, itemData: CollectionItem) => {
    e.stopPropagation()
    navigate(`/${routes.tokens}/${itemData.id}`)
  }

  return <>
    {props.items.map((item) => {
      return <DefaultCard
        id={item.id}
        key={item.id}
        imgUrl={item.videoUrl}
        isLandscapeOriented
      >
        {/* <Link onClick={onLinkClick}>{props.author}</Link> */}
        <CardInner>
          <CardLogoWrapper imgUrl={props.authorLogo} />
          <CardTitle>{item.name}</CardTitle>
          {item.description && <CardInfo>{kitcut(item.description)}</CardInfo>}
          {/* <SecondaryButton isSmall disabled={true}>Купить</SecondaryButton> */}
          <PrimaryButton isWide onClick={(e) => onClickView(e, item)}>Посмотреть</PrimaryButton>
        </CardInner>
      </DefaultCard>
    })}
  </>
}

export const BuyPage = () => {
  const tokenId = useParams().tokenId!
  const { api } = useStores()
  const [tokens, setTokens] = useState<Collection[]>([])
  const [displayedTokens, setDisplayedTokens] = useState<Collection[]>([])
  const [tags, setTags] = useState<ITag[]>([])
  const [showUpButton, setShowUpButton] = useState(false)
  const [isLoading, setLoading] = useState(false)

  const handleScroll = () => {
    setShowUpButton(window.pageYOffset > 100)
  }

  const throttledHandleScroll = throttle(handleScroll, 1000)

  useEffect(() => {
    window.addEventListener('scroll', throttledHandleScroll, false)

    return () => {
      window.removeEventListener('scroll', throttledHandleScroll, false)
    }
  }, [])

  useEffect(() => {
    const fetchTokens = async () => {
      setLoading(true)
      const [data, collections] = await Promise.all([api.getTokens(), api.getCollections()])
      let tagsToSet: ITag[] = []
      const tagsNames = new Set()
      const tokensToSet = data.map((d: any) => {
        const collectionData = collections[0]
        // для демо после убрать
        if (!tagsNames.has(d.tags[0].name)) {
          tagsNames.add(d.tags[0].name)
          tagsToSet = [...tagsToSet, ...d.tags]
        }
        return {
          id: collectionData.id,
          name: collectionData.name,
          authorName: collectionData.authorName,
          authorLogo: collectionData.authorIcon,
          previewImg: collectionData.collectionIcon,
          description: collectionData.description,
          items: [
            {
              id: d.id,
              name: d.name,
              description: d.description,
              videoUrl: d.small,
              authorName: collectionData.authorName,
              price: (d.price / 10 ** 8).toString(),
              tags: d.tags,
            },
          ],
        }
      })

      const nominationsOrder = {
        'Трендсеттер': [],
        'Большая повестка': [],
        'Социальная миссия': [],
        'Большие амбиции': [],
        'Идеальный шторм': [],
        'Для первого лица': [],
      }

      const sortedTokensByTag = tokensToSet.reduce((acc: any, cur: Collection) => {
        const [item] = cur.items
        const tag = item.tags[0].name
        if (acc[tag]) {
          acc[tag].push(cur)
        }
        return acc
      }, nominationsOrder)

      const sortedTokens = Object.values(sortedTokensByTag).flat() as Collection[]

      setTokens(sortedTokens)
      setDisplayedTokens(sortedTokens)
      setTags(tagsToSet)
      setLoading(false)
    }

    fetchTokens()
  }, [])

  const onChangeWithTags = (input: string, tags: string[]) => {
    let tokensToSet = tokens
    if (!input && !tags.length) {
      setDisplayedTokens(tokensToSet)
      return
    }
    if (tags.length) {
      tokensToSet = tokensToSet.filter(collection =>
        collection.items.find(item =>
          item.tags.find(tag =>
            tags.includes(tag.name))))
    }

    if (input) {
      const fuse = new Fuse(tokensToSet, {
        includeScore: true,
        ignoreLocation: true,
        keys: ['items.name', 'items.description', 'items.authorName', 'name'],
      })

      const toDisplay = fuse.search(input)
      setDisplayedTokens(toDisplay.filter((t: any) => t.score < 0.05).map((t: any) => t.item))
    } else {
      setDisplayedTokens(tokensToSet)
    }
  }

  return <>
    <NoiseBg />
    <BuyPageWrapper>
      <Banner />
      <Block marginTop={120} mobileMarginTop={70} />
      <SearchInputWithTags placeholder='Найдите NFT' tags={tags} onChangeWithTags={onChangeWithTags} />
      <Block marginTop={90} mobileMarginTop={50} />
      <CollectionCardsWrapper>
        { isLoading ?
          <CardsLoader length={6} /> :
          displayedTokens.map((collection, i) => (
            <CollectionItemCard
              key={i}
              {...collection}
            />),
          )
        }
      </CollectionCardsWrapper>
      <GoUpButton className={showUpButton ? 'show' : 'hide'} />
    </BuyPageWrapper>
  </>
}
