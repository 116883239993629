/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { PropsWithChildren } from 'react'
import styled from 'styled-components'
import routes from '../../routes'
import { Tabs } from './components/common/Tabs'
import { Block } from '../../components/Block'
import { devices } from '../../components/common/theme'

const ContentWrapper = styled.div`
  position: relative;
  box-sizing: border-box;
  background-image: linear-gradient(#2E3752, #0C101C);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 100vh;
  width: 100vw;
  padding-bottom: 64px;
  color: white;
`
const ContentInner = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  margin-top: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${devices.mobile} {
    margin-top: 50px;
  }
`

const Title = styled.div`
  font-family: PTSans, sans-serif;
  text-align: center;
  font-weight: 400;
  font-size: 48px;
  color: white;
  text-align: center;

  @media ${devices.mobile} {
    font-size: 32px;
    margin: 0 20px;
  }
`

const tabsItems = [{
  route: `/${routes.signIn}`,
  label: 'Войти',
}, {
  route: `/${routes.signUp}`,
  label: 'Регистрация',
}]

const Container = styled.div`
  width: 400px;
  margin: 0 auto;

  @media ${devices.tablet} {
    width: 60%;
  }

  @media ${devices.mobile} {
    width: 90%;
  }
`

type AuthRouteWrapperProps = {
  withTabs?: boolean,
  title?: string,
} & PropsWithChildren

export const AuthRouteWrapper = (props: AuthRouteWrapperProps) => (
  <>
    <ContentWrapper>
      <ContentInner>
        <Title>{props.title || 'Добро пожаловать'}</Title>
        <Container>
          {props.withTabs && <>
            <Block marginTop={50} />
            <Tabs items={tabsItems} />
            <Block marginTop={50} />
          </>
          }
          {props.children}
        </Container>
      </ContentInner>
    </ContentWrapper>
  </>
)
