const ModalService = {
  on(event: string, callback: (value: any) => void) {
    document.addEventListener(event, (e: any) => callback(e.detail))
  },
  open(component: (props: any) => JSX.Element, props = {}) {
    document.dispatchEvent(new CustomEvent('open', { detail: { component, props } }))
  },
}

export default ModalService
