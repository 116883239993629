/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import eyeOpened from '../resources/images/eye-opened.png'
import eyeClosed from '../resources/images/eye-closed.png'
import { devices } from './common/theme'

type FileInputProps = {
  text: string,
  isDeleteFile?: boolean,
} & React.InputHTMLAttributes<HTMLInputElement>

const DragNDropArea = styled.div`
    box-sizing: border-box;
    width: 100%;
    height: 136px;
    padding: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column nowrap;
    color: #fff;
    font-size: 16px;
    margin-bottom: 35px;
    background-color: ${props => props.theme.colors.secondaryBlack};
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='14' ry='14' stroke='%23333' stroke-width='4' stroke-dasharray='12' stroke-dashoffset='12' stroke-linecap='square'/%3e%3c/svg%3e");
    border-radius: 14px;
    user-select: none;
    position: relative;

    input {
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      cursor: pointer;
    }
`

const Wrapper = styled.div`
  width: 100%;
  margin-bottom: 35px;
`

export const Label = styled.div`
  opacity: 70%;
  font-weight: 700;
  margin-bottom: 5px;
`

const InputContainer = styled.input<{ disabled?: boolean, hasError?: boolean }>`
  background-color: ${({ disabled }) => disabled ? '#9D9D9D' : '#fff'};
  color: #000;
  font-size: 15px;
  padding: 16px;
  width: 100%;
  border-radius: 4px;
  box-sizing: border-box;
  margin-bottom: 5px;
  position: relative;

  ${({ disabled, theme }) => !disabled && `
      border: 1px solid #fff;
      :active, :focus {
      border: 1px solid ${theme.colors.green};
      box-shadow: 0 0 1.5rem rgba(0, 170, 120, 0.5);
    }
  `}

  ${({ hasError, theme }) => hasError && `
      ::placeholder {
      color: ${theme.colors.red}
    }
  `}
`

export const Error = styled.div`
  background-color: ${props => props.theme.colors.red};
  border-radius: 3px;
  height: 23px;
  display: flex;
  align-items: center;
  padding-left: 4px;
`

const TextareaContainer = styled.textarea<{ hasError?: boolean }>`
  background-color: #fff;
  color: #000;
  font-size: 15px;
  padding: 16px;
  width: 100%;
  border-radius: 4px;
  border: 1px solid #fff;
  height: 174px;
  resize: vertical;
  max-height: 400px;
  box-sizing: border-box;
  margin-bottom: 2px;

  :active, :focus {
    border: 1px solid ${props => props.theme.colors.green};
    box-shadow: 0 0 1.5rem rgba(0, 170, 120, 0.5);
  }

  ${({ hasError, theme }) => hasError && `
      ::placeholder {
      color: ${theme.colors.red}
    }
  `}
`

const InputWrapper = styled.div`
  position: relative;
`

const DragNDropInner = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  div {
    display: flex;
    align-items: center;
  }
`

const DragNDropDeleteBtn = styled.button`
  position: relative;
  z-index: 10;
  color: ${props => props.theme.colors.red};
`

const DragNDropFileImgWrapper = styled.div`
  width: 88px;
  height: 88px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 1rem;

  img {
    height: auto;
    width: 100%;
  }
`

export const FileInput = React.forwardRef((props: FileInputProps, ref: any) => {
  const [file, setFile] = useState<File | undefined>(undefined)
  const [previewUrl, setPreviewUrl] = useState<string | undefined>()
  const [isLoading, setLoading] = useState(false)

  const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    if (e.target.files && e.target.files[0]) {
      setLoading(true)
      setFile(e.target.files[0])
      setPreviewUrl(URL.createObjectURL(e.target.files[0]))
    }
  }

  useEffect(() => {
    if (file) {
      setLoading(false)
    }
  }, [file])

  useEffect(() => {
    if (props.isDeleteFile) {
      setFile(undefined)
    }
  }, [props.isDeleteFile])

  const onDeleteFile = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault()
    e.stopPropagation()
    setFile(undefined)
  }

  return (
    <DragNDropArea
      {...props}
    >
      {isLoading && 'Loading...'}
      {file ? (
        <DragNDropInner>
          <div>
            <DragNDropFileImgWrapper>
              <img src={previewUrl} alt='image' />
            </DragNDropFileImgWrapper>
            <span>{file.name}</span>
          </div>
          <DragNDropDeleteBtn onClick={onDeleteFile}>Удалить</DragNDropDeleteBtn>
        </DragNDropInner>
      )
        : <p>{props.text}</p>
      }
      <input
        ref={ref}
        id="file-input"
        type="file"
        name="image"
        onChange={changeHandler}
      />
    </DragNDropArea>
  )
})

type InputProps = {
  placeholder?: string,
  label?: string,
  disabled?: boolean,
  error?: string,
  isDirty?: boolean,
} & React.InputHTMLAttributes<HTMLInputElement> & React.HTMLAttributes<HTMLTextAreaElement>


const EyeIcon = styled.div<{isOpened?: boolean}>`
  position: absolute;
  background-image: ${({ isOpened }) => isOpened ? `url(${eyeOpened})` : `url(${eyeClosed})`};
  background-repeat: no-repeat;
  background-size: 40px;
  background-position: center;
  top: 20%;
  right: 3%;
  height: 32px;
  width: 32px;
  cursor: pointer;
`

export const Input = React.forwardRef((props: InputProps, ref: any) => {
  const [isPasswordHidden, setIsPasswordHidden] = useState(props.type === 'password')

  const toggleShowPassword = () => {
    if (props.isDirty) {
      setIsPasswordHidden(value => !value)
    }
  }

  return <Wrapper>
    <Label>{props.label}</Label>
    <InputWrapper>
      <InputContainer
        ref={ref}
        hasError={!!props.error}
        {...props}
        type={isPasswordHidden ? 'password' : 'text'}
      />
      {props.type === 'password' && <EyeIcon isOpened={isPasswordHidden} onClick={toggleShowPassword} />}
    </InputWrapper>
    {props.error && <Error>{props.error}</Error>}
  </Wrapper>
})

export const Textarea = React.forwardRef((props: InputProps, ref: any) => {
  return <Wrapper>
    <Label>{props.label}</Label>
    <TextareaContainer
      ref={ref}
      hasError={!!props.error}
      {...props}
    />
    {props.error && <Error>{props.error}</Error>}
  </Wrapper>
})

export const InputTooltip = styled.div<{ isVisible?: boolean }>`
  display: ${props => props.isVisible ? 'block' : 'none'};
  position: absolute;
  padding: 8px 0;
  background: white;
  border-radius: 5px;
  margin: auto;
  top: 0;
  left: 100%;
  width: 320px;
  margin-left: 16px;
  box-shadow: 0px 4px 72px rgba(0, 0, 0, 0.15);
  z-index: 10;
  
  &:after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border-width: 8px;
    border-style: solid;
    border-color: transparent #FFFFFF transparent transparent;
    top: 20px;
    left: -16px;
  }

  @media only screen and (max-width: 800px) {
    top: 70px;
    left: 0;
    right: 0;
    width: 100%;
    margin-left: unset;

    &:after {
      border-color: transparent transparent #FFFFFF transparent;
      top: -16px;
      left: calc(50% - 8px);
    }
  }
`

