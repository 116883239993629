/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react'
import styled from 'styled-components'
import 'react-toastify/dist/ReactToastify.css'
import { MainRouteWrapper } from './modules/main/MainRouteWrapper'
import { AuthRouteWrapper } from './modules/auth/AuthRouteWrapper'
import {  Route, Routes } from 'react-router-dom'
import { routes as authRoutes } from './modules/auth/routes'
import { routes as mainRoutes } from './modules/main/routes'
import { ProtectedRoute } from './modules/ProtectedRoute'
import { ModalRoot } from './components/modal/ModalRoot'
import { ToastContainer } from 'react-toastify'
import { ToastCloseButton } from './components/Notifications'

const ToastWrapper = styled.div`
  position: absolute;
  left: 0px;
  top: 0px;
`

export const Content = () => {
  return (
    <>
      <ToastWrapper>
        <ToastContainer closeButton={ToastCloseButton} />
      </ToastWrapper>
      <Routes>
        {authRoutes.map(route => (
          <Route
            key={route.path}
            path={route.path}
            element={
              <AuthRouteWrapper
                withTabs={route.withTabs}
                title={route.title}>
                <route.element />
              </AuthRouteWrapper>}
          />
        ))}
        {mainRoutes.map(route => (
          <Route
            path={route.path}
            key={route.path}
            element={
              route.isPrivate ?
                <ProtectedRoute>
                  <MainRouteWrapper>
                    <route.element />
                  </MainRouteWrapper>
                </ProtectedRoute> :
                <MainRouteWrapper>
                  <route.element />
                </MainRouteWrapper>
            }
          />
        ))}
      </Routes>
      <ModalRoot />
    </>
  )
}
