import React from 'react'
import styled from 'styled-components'
import successIcon from '../../../resources/images/icon-success.png'
import { Modal, CustomModalProps } from '../../../components/modal/Modal'

const IconWrapper = styled.div`
  margin: 2rem auto;
  background-image: url(${successIcon});
  width: 126px;
  height: 126px;
  background-size: cover;
`

const Info = styled.div`
  font-size: 16px;
`

const ButtonWrapper = styled.div`
  margin-top: 5rem;
`

type SuccessModalProps = {
  text: string,
  buttonsBlock: JSX.Element,
} & CustomModalProps

export const Success = (props: SuccessModalProps) => {
  return (
    <Modal title='Получение NFT успешно' close={props.close}>
      <Info>
        <IconWrapper />
        {props.text}
      </Info>
      <ButtonWrapper>
        {props.buttonsBlock}
      </ButtonWrapper>
    </Modal>
  )
}
