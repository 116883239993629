/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Input, InputTooltip } from '../../../components/Input'
import { PrimaryButton } from '../../../components/Button'
import { useForm } from 'react-hook-form'
import { PasswordRules, validatePassword } from '../PasswordRules'
import useStores from '../../../hooks/useStores'
import { FormErrors } from '../../../constants'
import { validateEmail } from '../utils'
import { AxiosError } from 'axios'
import { toast } from 'react-toastify'
import { Notification } from '../../../components/Notifications'
import { Block } from '../../../components/Block'
import { useNavigate, useLocation } from 'react-router-dom'
import routes from '../../../routes'
import { LocationParams } from '../../../types'
import { useActiveElement } from '../useActiveElement'

export const InputWrapper = styled.div`
  position: relative;
`

export const InfoText = styled.div<{size: number, bold?: boolean}>`
  font-size: ${({ size }) => `${size}px`};
  font-weight: ${({ bold }) => bold && 600 };
  text-align: center;
`

const ButtonWrapper = styled.div`
  width: 75%;
  margin: 0 auto;
`

const handleSignUpError = (e: AxiosError) => {
  console.error('Sign up error', e.message)
  const { errors = [] } =  e.response && e.response.data as any
  let emailError = ''
  let passwordError = ''
  if (errors.includes('user.already.exists')) {
    emailError = 'Пользователь с таким email уже существует'
  } else if (errors.includes('password.does.not.meet.the.requirements')) {
    passwordError = 'Пароль не соответствует требованиям'
  } else {
    emailError = 'Неизвестная ошибка.'
  }
  toast(<Notification isError message={emailError || passwordError} />, {
    hideProgressBar: true,
    autoClose: 5000,
  })
}

export const SignUp = () => {
  const navigate = useNavigate()
  const { register, handleSubmit, watch, formState: { errors, isValid } } = useForm({ mode: 'onSubmit' })
  const { activeElement } = useActiveElement()
  const { authStore } = useStores()

  const getIsVisible = (inputName: string) => {
    return !!activeElement && activeElement.getAttribute && activeElement.getAttribute('name') === inputName
  }

  const onSubmit = async (data: any) => {
    const { email, password } = data
    try {
      await authStore.signUp(email, password)
      navigate(`/${routes.confirm}`, { state: { email: watch('email') } })
    } catch (e: any) {
      handleSignUpError(e)
    }
  }

  return <form onSubmit={handleSubmit(onSubmit)}>
    <Input
      placeholder='Электронная почта'
      {...register('email', {
        required: FormErrors.EnterEmail,
        validate: validateEmail,
        setValueAs: v => v.trim(),
      })}
      name='email'
      error={errors.email && (errors.email.message || (errors.email.type === 'validate' && FormErrors.EmailIsIncorrect))}
    />
    <InputWrapper>
      <Input
        placeholder='Пароль'
        {...register('password', {
          required: FormErrors.EnterPassword,
          setValueAs: v => v.trim(),
          validate: (v) => validatePassword(v),
        })}
        type='password'
        isDirty={!!watch('password')}
        error={errors.password && (errors.password.message || (errors.password.type === 'validate' && FormErrors.PasswordIsNotValid))}
      />
      <InputTooltip isVisible={getIsVisible('password')}>
        <PasswordRules password={watch('password')} />
      </InputTooltip>
    </InputWrapper>
    <InputWrapper>
      <Input
        placeholder='Подтвердите пароль'
        {...register('confirm', {
          required: FormErrors.EnterConfirm,
          validate: (v) => v === watch('password'),
          setValueAs: v => v.trim(),
        })}
        type='password'
        isDirty={!!watch('confirm')}
        error={errors.confirm && (errors.confirm.message || (errors.confirm.type === 'validate' && FormErrors.PasswordsNotMatch))}
      />
      <InputTooltip isVisible={getIsVisible('confirm')}>
        <PasswordRules password={watch('confirm')} />
      </InputTooltip>
    </InputWrapper>
    <PrimaryButton isWide type='submit'>Зарегистрироваться</PrimaryButton>
  </form>
}

export const ConfirmRegistration = () => {
  const navigate = useNavigate()
  const location = useLocation() as LocationParams<{email: string}>

  return <>
    <Block marginTop={50} />
    <InfoText size={16}>Мы отправили сообщение на адрес <br />
      <b>{location.state?.email || ''}</b>
    </InfoText>
    <Block marginTop={24} />
    <InfoText size={14}>Перейдите по ссылке для подтвеждения электронной почты</InfoText>
    <Block marginTop={50} />
    <ButtonWrapper>
      <PrimaryButton isWide onClick={() => navigate(routes.signUp)}>Войти</PrimaryButton>
    </ButtonWrapper>
  </>
}