import React from 'react'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'
import arrowLink from '../resources/images/arrow-link.png'

type NavigationLinkProps = {
  to: string,
  children?: any,
}

type LinkProps = {
  children?: any,
} & React.HTMLAttributes<HTMLAnchorElement>


const LinkInner = styled.div`
  font-size: 17px;
  position: relative;
`

const CardLinkArrow = styled.div`
  display: inline-block;
  background-image: url(${arrowLink});
  background-size: 25px;
  width: 25px;
  height: 25px;
  position: absolute;
  bottom: 0px;
`

export const Link = (props: LinkProps) => {
  return <a {...props}>
    <LinkInner>
      {props.children}
      <CardLinkArrow />
    </LinkInner>
  </a>
}

export const NavigationLink = (props: NavigationLinkProps) => {
  return <NavLink to={props.to}>
    <LinkInner>
      {props.children}
      <CardLinkArrow />
    </LinkInner>
  </NavLink>
}