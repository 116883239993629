/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react'
import ModalService from './modal-service'
import { useLocation } from 'react-router-dom'
import { BodyScrollController } from '../common/theme'

type ModalRootState = {
  component: () => JSX.Element,
  props: any,
  close: () => void,
}

export const ModalRoot = () => {
  const location = useLocation()
  const [modal, setModal] = useState<ModalRootState | Record<string, never>>({})

  useEffect(() => {
    ModalService.on('open', ({ component, props }: ModalRootState) => {
      setModal({
        component,
        props,
        close: () => {
          setModal({})
          BodyScrollController.scroll()
        },
      })
      BodyScrollController.noScroll()
    })
  }, [])

  useEffect(() => {
    setModal({})
  }, [location.pathname])

  const ModalComponent = modal.component ? modal.component : null

  return (
    ModalComponent && (
      <ModalComponent
        { ...modal.props }
        close={ modal.close }
      />
    )
  )
}