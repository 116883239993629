import React, { useRef } from 'react'
import styled from 'styled-components'
import crossIcon from '../../resources/images/cross-icon.png'
import { devices } from '../common/theme'

type ModalProps = {
  title?: string,
  children: JSX.Element | JSX.Element[],
} & CustomModalProps

export type CustomModalProps = {
  hideButton?: boolean,
  close: () => void,
}

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(12, 16, 28, 0.7);
  width: 100%;
  height: 100%;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
`

const ModalWrapper = styled.div`
  width: 800px;
  min-height: 500px;
  box-shadow: 0 5px 16px rgba(0, 0, 0, 0.5);
  background: ${props => props.theme.colors.black};
  color: #fff;
  z-index: 11;
  border-radius: 8px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;

  @media ${devices.mobile} {
    justify-content: start;
    width: 100%;
    min-height: 100%;
    height: 100%;
    overflow-y: scroll;
  }
`

const ModalTitle = styled.div`
  height: 20%;

  h1 {
    margin: 1rem 0;
  }

  @media ${devices.mobile} {
    font-size: 10px;
    margin: 0;
    height: auto;

    h1 {
      text-align: center;
      width: 200px;
    }
  }
`

const ModalContent = styled.div`
  width: 85%;
  padding-bottom: 2rem;
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  line-height: 1.8;

  @media ${devices.mobile} {
    height: auto;
  }
`

const CloseModalButton = styled.button`
  background-color: transparent;
  position: absolute;
  top: 20px;
  right: 20px;
  width: 32px;
  height: 32px;
  background-size: cover;
  background-image: url(${crossIcon});
  z-index: 10;
`

export const Modal = (props: ModalProps) => {
  const modalRef = useRef<HTMLDivElement>(null)

  const closeModal = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (modalRef.current === e.target) {
      props.close()
    }
  }

  return (
    <Overlay ref={modalRef} onClick={closeModal}>
      <ModalWrapper>
        <ModalTitle>{props.title && <h1>{props.title}</h1>}</ModalTitle>
        <ModalContent>
          {props.children}
        </ModalContent>
        <CloseModalButton onClick={props.close} />
      </ModalWrapper>
    </Overlay>
  )
}

