import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { ITag } from '../types'

type TagsProps = {
  options: ITag[],
  onChange?: (tags: string[]) => void,
}

const Ul = styled.ul`
  display: flex;
  flex-wrap: wrap;
  margin-top: 2.5rem;
  gap: .5rem;
`

const Li = styled.li`
  display: flex;
`
const Label = styled.label<{selected?: boolean}>`
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.2);
  padding: 6px 15px;
  border-radius: 2px;
  transition: all .2s ease-in-out;
  white-space: nowrap;
  
  :hover {
    background-color: rgba(255, 255, 255, 0.3);
  }

  ${({ selected }) => selected && `
    background-color: rgba(0, 170, 120, 0.2);
    color: #00aa78;
    :hover {
      background-color: rgba(0, 170, 120, 0.3);
    }
  `}
`

const Input = styled.input`
  display: none;
`

export const Tags = (props: TagsProps) => {
  const [checkedState, setCheckedState] = useState<any[]>([])

  useEffect(() => {
    setCheckedState(new Array(props.options.length).fill(false))
  },[props.options])

  const handleChange = (position: number) => {
    const updatedCheckedState = checkedState.map((item, index) =>
      index === position ? !item : item,
    )
    setCheckedState(updatedCheckedState)
    if (props.onChange) {
      const selectedTags = props.options.reduce<string[]>((acc, tag, i) => {
        if (updatedCheckedState[i]) {
          acc.push(tag.name)
        }
        return acc
      }, [])
      props.onChange(selectedTags)
    }
  }

  return <Ul>
    {props.options.map((tag, i) => (
      <Li key={i}>
        <Label selected={ checkedState[i] }>
          <Input type='checkbox' name={`tag-${i}`} value={tag.name} checked={checkedState[i]} onChange={() => handleChange(i)} />
          {tag.name}
        </Label>
      </Li>
    ))}
  </Ul>
}