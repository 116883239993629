/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react'
import styled, { css } from 'styled-components'
import arrowLeft from '../resources/images/arrow-left.png'
import { useNavigate } from 'react-router-dom'
import { HashLink as Link } from 'react-router-hash-link'
import topBtn from '../resources/images/top-btn.png'
import { devices } from './common/theme'

type ButtonProps = {
  disabled?: boolean,
  children?: any,
  isSmall?: boolean,
  isWide?: boolean,
} & React.ComponentPropsWithoutRef<'button'>

export const PrimaryButtonContainer = styled.button<{ isSmall?: boolean, isWide?: boolean, disabled?: boolean }>`
  width: ${({ isWide }) => isWide && '100%'};
  background-color: ${props => props.theme.colors.green};
  padding: 1rem 3rem;
  border-radius: 3px;
  font-size: 16px;
  transition: all .2s ease-in-out;

  :hover {
    transform: scale(1.05)
  }

  ${({ isSmall }) => isSmall && `
    padding: 1rem;
    border: none;
  `}

  ${({ disabled }) => disabled && `
    background-color: gray;
    opacity: 0.5;
    :hover{
      transform: none;
    }
  `}
`

export const HeaderButton = styled(PrimaryButtonContainer)`
  border: 1px solid ${props => props.theme.colors.green};
  background-color: transparent;
  padding: 1rem 1.5rem;
`

export const SecondaryButtonContainer = styled(PrimaryButtonContainer)`
  background-color: rgba(255, 255, 255, 0.2);;
`

const goBackButtonStyles = css`
  position: absolute;
  display: flex;
  align-items: center;
  top: 0px;
  left: 0px;
  z-index: 2;
  font-size: 20px;
  padding: 12px 20px 12px 16px;
  border-radius: 4px;
  font-weight: 700;
  background-color: ${props => props.theme.colors.black}; 

  @media ${devices.mobile} {
    padding-left: 0;
  }
`

const GoBackButtonContainer = styled.button` ${goBackButtonStyles} `
const GoBackLinkContainer = styled(Link)` ${goBackButtonStyles} `

const GoUpButtonContainer = styled.button` 
  position: fixed;
  bottom: 30px;
  right: calc(100vw - 85%);
  opacity: 0;
  visibility: hidden;
  height: 96px;
  width: 96px;
  background: url(${topBtn});
  background-size: contain;
  z-index: 5;

  @media (max-width: 2000px) {
    right: calc(100vw - 90%);
  }

  @media (max-width: 1800px) {
    right: 30px;
  }

  @media ${devices.tabletAndMobile} {
    right: 10px;
    bottom: 10px;
  }

  &.show {
    visibility: visible;
    opacity: 1;
    transition: opacity 1s, visibility 1s;
  }

  &.hide {
    visibility: hidden;
    opacity: 0;
    transition: opacity 1s, visibility 1s;
  }
`

const ArrowLeft = styled.div`
  display: inline-block;
  background-image: url(${arrowLeft});
  background-size: contain;
  height: 32px;
  width: 32px;
`

export const CenteredButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const GoBackButton = (props: ButtonProps) => {
  const navigate = useNavigate()

  const onClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (props.onClick) {
      props.onClick(e)
      return
    }
    navigate(-1)
  }

  return <GoBackButtonContainer {...props} onClick={onClick}>
    <ArrowLeft /> Назад
  </GoBackButtonContainer>
}

export const GoBackToElementButton = ({ to }: {to: string}) => {
  return <GoBackLinkContainer to={to}>
    <ArrowLeft /> Назад
  </GoBackLinkContainer>
}

export const GoUpButton = (props: ButtonProps) => {
  const navigate = useNavigate()

  const goUp = () => {
    navigate('/', { replace: true })
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  return <GoUpButtonContainer {...props} onClick={goUp}  />
}

export const PrimaryButton = (props: ButtonProps) => {

  const onClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (!props.disabled && props.onClick) {
      props.onClick(e)
    }
  }
  return <PrimaryButtonContainer {...props} onClick={onClick}>{props.children}</PrimaryButtonContainer>
}

export const SecondaryButton = (props: ButtonProps) => {

  const onClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (!props.disabled && props.onClick) {
      props.onClick(e)
    }
  }
  return <SecondaryButtonContainer {...props} onClick={onClick}>{props.children}</SecondaryButtonContainer>
}