/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react'
import { useForm } from 'react-hook-form'
import { Block } from '../../components/Block'
import { Input, InputTooltip } from '../../components/Input'
import { FormErrors, ResetPasswordErrors } from '../../constants'
import { CenteredButtonsWrapper, PrimaryButton } from '../../components/Button'
import { LinkWrapper } from './components/PasswordRecovery'
import { AdditionalText } from './components/SignIn'
import routes from '../../routes'
import { useActiveElement } from './useActiveElement'
import { InputWrapper } from './components/SignUp'
import { PasswordRules, validatePassword } from './PasswordRules'
import useStores from '../../hooks/useStores'
import { useNavigate, useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Notification } from '../../components/Notifications'

export const ResetPassword = () => {
  const { register, handleSubmit, formState: { errors }, watch, reset } = useForm({ mode: 'onSubmit' })
  const { activeElement } = useActiveElement()
  const { api } = useStores()
  const navigate = useNavigate()
  const query = new URLSearchParams(useLocation().search)
  const jwt = query.get('token')!

  const getIsVisible = (inputName: string) => {
    return !!activeElement && activeElement.getAttribute && activeElement.getAttribute('name') === inputName
  }

  const onSubmit = async () => {
    try {
      await api.resetPassword(jwt, watch('newPassword'))
      toast(<Notification message="Пароль успешно изменен." />, {
        autoClose: 5000,
      })
      navigate(`/${routes.signIn}`)
    } catch (e: any) {
      const { errors = [] } =  e.response && e.response.data as any
      let errorMessage
      if (errors.includes('token.expired')) {
        errorMessage = 'Время для смены пароля истекло. Попробуйте еще раз'
      } else {
        errorMessage = 'Что-то пошло не так. Попробуйте еще раз'
      }
      toast(<Notification message={errorMessage} />)
    }
  }

  return <form onSubmit={handleSubmit(onSubmit)}>
    <Block marginTop={100} />
    <InputWrapper>
      <Input
        type='password'
        placeholder='Новый пароль'
        {...register('newPassword', {
          required: ResetPasswordErrors.EnterNewPassword,
          setValueAs: v => v.trim(),
          validate: (v) => validatePassword(v),
        })}
        isDirty={!!watch('newPassword')}
        error={errors.newPassword && (errors.newPassword.message || (errors.newPassword.type === 'validate' && FormErrors.PasswordIsNotValid))}
      />
      <InputTooltip isVisible={getIsVisible('newPassword')}>
        <PasswordRules password={watch('newPassword')} />
      </InputTooltip>
    </InputWrapper>
    <Block marginTop={30} />
    <InputWrapper>
      <Input
        type='password'
        placeholder='Подтвердите новый пароль'
        {...register('confirmNewPassword', {
          required: ResetPasswordErrors.EnterConfirmPassword,
          setValueAs: v => v.trim(),
          validate: (v) => v === watch('newPassword'),
        })}
        isDirty={!!watch('confirmNewPassword')}
        error={errors.confirmNewPassword && (errors.confirmNewPassword.message || (errors.confirmNewPassword.type === 'validate' && FormErrors.PasswordsNotMatch))}
      />
      <InputTooltip isVisible={getIsVisible('confirmNewPassword')}>
        <PasswordRules password={watch('confirmNewPassword')} />
      </InputTooltip>
    </InputWrapper>
    <CenteredButtonsWrapper>
      <PrimaryButton isWide type='submit'>Изменить пароль</PrimaryButton>
      <LinkWrapper>
        <AdditionalText to={`/${routes.signIn}`}>Вернуться</AdditionalText>
      </LinkWrapper>
    </CenteredButtonsWrapper>
  </form>
}