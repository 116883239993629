/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { DefaultCard, CollectionCardsWrapper, CardTitle, CardInfo, CardsLoader, CardInner } from '../../../components/Card'
import routes from '../../../routes'
import { CollectionItemFullInfo } from '../../../types'
import moment from 'moment'
import { Block } from '../../../components/Block'
import useStores from '../../../hooks/useStores'
import { PrimaryButton, CenteredButtonsWrapper } from '../../../components/Button'
import { useNavigate } from 'react-router-dom'
import downloadIcon from '../../../resources/images/download-icon.png'
import { devices } from '../../../components/common/theme'
import { Notification } from '../../../components/Notifications'
import { toast } from 'react-toastify'
import { useInterval } from '../../../hooks/useInterval'
import { MY_COLLECTION_REFRESH_INTERVAL } from '../../../constants'
const InfoBlock = styled.div` opacity: 60%; `

const CenteredWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const ButtonWrapper = styled.div`
  width: 25%;
`

const Empty = styled.div`
  margin: 0 auto;
  width: 50%;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='7' ry='7' stroke='%23333' stroke-width='3' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  border-radius: 7px;
  padding: 40px;
  text-align: center;
  font-size: 16px;

  p {
    opacity: 70%;
  }
`

const LoadingText = styled.div`
  text-align: center;
  font-size: 17px;

  &.show {
    visibility: visible;
    opacity: 1;
    transition: opacity .3s, visibility .3s;
  }

  &.hide {
    visibility: hidden;
    opacity: 0;
    transition: opacity .3s, visibility .3s;
  }
`

const DownloadButton = styled.a`
  background-image: url(${downloadIcon});
  background-size: cover;
  width: 20px;
  height: 20px;
  background-color: rgba(255, 255, 255, 0.1);
  padding: 16px;
  border-radius: 4px;
  transition: all .2s ease-in-out;

  :hover {
    transform: scale(1.1);
  }
`

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  button {
    width: 75%;
  }
`

const CollectionCard = (props: CollectionItemFullInfo) => {
  const { api } = useStores()
  const navigate = useNavigate()
  const [hasError, setError] = useState(false)
  const [downloadLink, setDownloadLink] = useState('')

  useEffect(() => {
    const getLink = async () => {
      try {
        const downloadUrl = await api.getFile(props.originalHash!)
        setDownloadLink(downloadUrl)
      } catch (e) {
        setError(true)
      }
    }
    getLink()
  }, [])

  const onClickView = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    navigate(`/${routes.tokens}/${props.id}`, { state: { fromMyCollection: true } })
  }

  const onLinkClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.stopPropagation()
    toast.dismiss()
    if (hasError) {
      return toast(<Notification message='Не удалось загрузить файл. Попробуйте ещё раз' />)
    }
  }

  return <DefaultCard imgUrl={props.videoUrl} isLandscapeOriented>
    <CardInner>
      <CardTitle>{props.name}</CardTitle>
      <CardInfo>{props.description}</CardInfo>
      {/* <NavigationLink to={`/${routes.collections}/${props.collectionId}`}>Перейти к коллекции</NavigationLink> */}
      <ButtonsWrapper>
        <PrimaryButton onClick={(e) => onClickView(e)}>Посмотреть</PrimaryButton>
        <DownloadButton href={downloadLink || undefined} download onClick={onLinkClick} />
      </ButtonsWrapper>
    </CardInner>
  </DefaultCard>
}

const CollectionCardsWrap = styled(CollectionCardsWrapper)`
  justify-content: flex-start;
  gap: 2.2rem;

  @media ${devices.tabletAndMobile} {
    justify-content: center;
  }
`

export const MyCollection = () => {
  const [isLoading, setLoading] = useState(false)
  const { api, keysStore } = useStores()
  const [myTokens, setMyTokens] = useState<CollectionItemFullInfo[]>([])
  const navigate = useNavigate()

  const fetchTokens = async () => {
    const myTokens = await api.getTokensByAddress(keysStore.address)

    setMyTokens(myTokens.map((t: any) => ({
      id: t.id,
      name: t.name,
      description: t.description,
      videoUrl: t.small,
      author: 'Author 1',
      price: t.price,
      originalHash: t.file,
    })))
  }

  useEffect(() => {
    const fetch = async () => {
      setLoading(true)
      await fetchTokens()
      setLoading(false)
    }
    fetch()
  }, [])

  useInterval(async () => {
    await fetchTokens()
  }, MY_COLLECTION_REFRESH_INTERVAL)

  if (!isLoading && !myTokens.length) {
    return <CenteredWrapper>
      <Block marginTop={100} />
      <Empty><p>У вас пока нет NFT</p></Empty>
      <Block marginTop={40} />
      <ButtonWrapper>
        <CenteredButtonsWrapper>
          <PrimaryButton onClick={() => navigate('/')}>Посмотреть все NFT</PrimaryButton>
        </CenteredButtonsWrapper>
      </ButtonWrapper>
    </CenteredWrapper>
  }

  return <>
    <Block marginTop={100} />
    <LoadingText className={isLoading ? 'show' : 'hide'}>Мы загружаем ваши NFT...</LoadingText>
    <Block marginTop={30} />
    <CollectionCardsWrap>
      {isLoading ?
        <CardsLoader length={3} /> :
        myTokens.map((tokenData, i) => (
          <CollectionCard
            key={tokenData.id}
            {...tokenData}
          />),
        )}
    </CollectionCardsWrap>
  </>
}
