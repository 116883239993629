import { TokenRefresherConfig } from './types'
import { TokenRefresher } from './refresher'
import { AxiosInstance, AxiosRequestConfig, default as axiosConstructor } from 'axios'

export class ApiTokenRefresher {
  private readonly axiosRequestConfig?: AxiosRequestConfig
  private readonly tokenRefresher: TokenRefresher

  constructor(config: TokenRefresherConfig) {
    this.axiosRequestConfig = config.axiosRequestConfig
    this.tokenRefresher = new TokenRefresher(config)
  }

  init() {
    return {
      axios: this.getAxios(),
    }
  }

  private getAxios = (): AxiosInstance => {
    const axios = axiosConstructor.create(this.axiosRequestConfig)

    axios.interceptors.request.use(async (config) => {
      const accessToken = await this.tokenRefresher.getAccessToken()
      config.headers = {
        ...config.headers,
        Authorization: `Bearer ${accessToken}`,
      }
      return config
    })

    return axios
  }
}
