import React, { PropsWithChildren } from 'react'
import styled from 'styled-components'
import { Header } from '../../components/Header'
import { devices } from '../../components/common/theme'

const ContentWrapper = styled.div`
  max-width: 1440px;
  margin: 0 auto;

  @media ${devices.tablet} {
    width: 100vw;
  } 

  @media ${devices.mobile} {
    width: 100vw;
  } 
`

const ContentInner = styled.div`
  margin: 0 100px;

  @media (max-width: 1230px) {
    margin: ${props => `${props.theme.mobileHeaderHeight + 30}px 100px`};
  } 

  @media ${devices.tablet} {
    margin: ${props => `${props.theme.mobileHeaderHeight + 30}px 40px`};
  } 

  @media ${devices.mobile} {
    margin: ${props => `${props.theme.mobileHeaderHeight + 30}px 10px`};
  } 
`

export const MainRouteWrapper = (props: PropsWithChildren) => {
  return <ContentWrapper>
    <Header />
    <ContentInner>
      {props.children}
    </ContentInner>
  </ContentWrapper>
}