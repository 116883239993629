import React, { useState } from 'react'
import { Input } from '../../../components/Input'
import { PrimaryButton, CenteredButtonsWrapper } from '../../../components/Button'
import { useForm } from 'react-hook-form'
import { Block } from '../../../components/Block'
import { AdditionalText } from './SignIn'
import routes from '../../../routes'
import styled from 'styled-components'
import { FormErrors } from '../../../constants'
import { validateEmail } from '../utils'
import useStores from '../../../hooks/useStores'
import { InfoText } from './SignUp'
import { toast } from 'react-toastify'
import { Notification } from '../../../components/Notifications'
import { AuthError } from '../../../api/apiErrors'

export const LinkWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 1rem;
`

export const PasswordRecovery = () => {
  const { api } = useStores()
  const [isEmailSent, setEmailSent] = useState(false)
  const { register, handleSubmit, formState: { errors }, watch, reset } = useForm({ mode: 'onSubmit' })

  const onSubmit = async (data: any) => {
    const { email } = data
    try {
      await api.sendPasswordRecover(email)
      setEmailSent(true)
    } catch (e: any) {
      console.error('Recover error:', e.message)
      let toastMessage = 'Неизвестная ошибка.'
      let toastTitle = ''
      if (e.response) {
        const { data: { errors = [] } } = e.response
        if (errors.includes(AuthError.TooManyRequests)) {
          toastMessage = 'Слишком много запросов'
          toastTitle = 'Попробуйте позже'
        }
      }
      toast(<Notification isError title={toastTitle} message={toastMessage} />, {
        hideProgressBar: true,
      })
    }

  }

  if (isEmailSent) {
    const onResetClick = () => {
      setEmailSent(false)
      reset()
    }
    return <>
      <Block marginTop={50} />
      <InfoText size={18}>
      Если пользователь с адресом электронной почты:
        <Block marginTop={15} />
        <b>{watch('email')}</b>
        <Block marginTop={15} />
      существует, сообщение со ссылкой для восстановления пароля было отправлено на почту.
      </InfoText>
      <Block marginTop={20} />
      <InfoText size={18}>
      Если сообщения нет во входящих, проверьте папку спам
      </InfoText>
      <Block marginTop={50} />
      <CenteredButtonsWrapper>
        <PrimaryButton onClick={onResetClick}>Отправить другой email</PrimaryButton>
        <LinkWrapper>
          <AdditionalText to={`/${routes.signIn}`}>Вернуться к авторизации</AdditionalText>
        </LinkWrapper>
      </CenteredButtonsWrapper>
    </>
  }

  return <form onSubmit={handleSubmit(onSubmit)}>
    <Block marginTop={100} />
    <Input
      placeholder='Email'
      {...register('email', {
        required: FormErrors.EnterEmail,
        validate: validateEmail,
      })}
      error={errors.email && (errors.email.message || (errors.email.type === 'validate' && FormErrors.EmailIsIncorrect))}
    />
    <Block marginTop={50} />
    <CenteredButtonsWrapper>
      <PrimaryButton isWide type='submit'>Восстановить пароль</PrimaryButton>
      <LinkWrapper>
        <AdditionalText to={`/${routes.signIn}`}>Вернуться</AdditionalText>
      </LinkWrapper>
    </CenteredButtonsWrapper>
  </form>
}