/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react'
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import styled, { css } from 'styled-components'
import { GoBackToElementButton, GoBackButton, PrimaryButton } from '../../../../components/Button'
import { HorisontalDivider } from '../../../../components/Divider'
import useStores from '../../../../hooks/useStores'
import { addBuyModal } from './BuyPage'
import { CollectionItemFullInfo, LocationParams } from '../../../../types'
import { VideoContainer } from '../../../../components/VideoContainer'
import { devices } from '../../../../components/common/theme'
import { Plug } from '../../../../components/Card'
import { Block } from '../../../../components/Block'
import moment from 'moment'

const Wrapper = styled.div`
  position: relative;
  margin: 0 auto;

  @media ${devices.tabletAndMobile} {
    width: 100%;
  }
`

const Inner = styled.div`
  padding-top: 124px;
  display: flex;
  justify-content: space-between;

  @media ${devices.tabletAndMobile} {
    padding-top: 80px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`

const VideoWrapper = styled.div`
  width: 450px;
  max-width: 450px;
  max-height: 355px;
  overflow: hidden;
  border: 1px solid rgba(180, 180, 180, 1);
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  z-index: 2;

  @media ${devices.tabletAndMobile} {
    width: auto;
    max-width: auto;
    background-size: cover;
    background-repeat: no-repeat;
  }
`

const InfoBlock = styled.div`
  width: 35%;
  margin: 0 auto;
  word-wrap: break-word;

  @media ${devices.tablet} {
    margin-top: 30px;
    width: 50%;
  }

  @media ${devices.mobile} {
    max-width: 350px;
    margin-top: 30px;
    width: 95%;
  }
`

const ItemTitle = styled.div`
  font-size: 40px;
  font-weight: 700;

  @media ${devices.mobile} {
    font-size: 32px;
  }
`

const Info = styled.div`
  margin: 25px 0;

  h4 {
    opacity: 60%;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 0.5rem;
  }

  p {
    font-size: 16px;
    margin: 0;
  }
`

const Price = styled.p`
  font-size: 24px;
`
const ItemFooter = styled.div`
  margin-top: 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const OwnerBlock = styled.div`
  width: 250px;
  background-color: ${props => props.theme.colors.green};
  padding: 8px 16px;
  border-radius: 3px;
  font-size: 16px;
  text-align: center;
  margin: 30px 0;
`

export const CollectionItem = () => {
  const location = useLocation() as LocationParams<{fromMyCollection: boolean}>
  const { api, keysStore } = useStores()
  const tokenId = useParams().tokenId!
  const [itemData, setItemData] = useState<CollectionItemFullInfo>()
  const collection = itemData?.collection!

  useEffect(() => {
    const getTokenInfo = async () => {
      const [data, tokenInfo] = await Promise.all([api.getToken(tokenId), api.getTokenInfo(tokenId)])
      // временное решение для демки
      const { issuer, owner } = tokenInfo
      data.issuer = issuer
      data.owner = owner
      // end
      setItemData({ ...data, videoUrl: data.medium })
    }

    getTokenInfo()
  }, [])

  if (itemData) {
    return <Wrapper>
      {location.state?.fromMyCollection ?
        <GoBackButton /> :
        <GoBackToElementButton to={`/#${itemData.id}`}  />}
      <Inner>
        <VideoWrapper>
          <VideoContainer src={itemData.videoUrl} />
        </VideoWrapper>
        <InfoBlock>
          <ItemTitle>{itemData.name}</ItemTitle>
          {
            keysStore?.address === itemData.owner &&
              <OwnerBlock>Вы являетесь владельцем</OwnerBlock>
          }
          <Info>
            <h4>Коллекция</h4>
            <p>{collection.name}</p>
          </Info>
          <Info>
            <h4>Об NFT</h4>
            <p>{itemData.description}</p>
          </Info>
          <Info>
            <h4>Описание коллекции</h4>
            <p>{collection.description}</p>
          </Info>
          <Info>
            <h4>Категория</h4>
            <p>{itemData.tags[0].name}</p>
          </Info>
          {/* <HorisontalDivider />
          <ItemFooter>
            <Price>{itemData.price} &#x20bd;</Price>
            <PrimaryButton onClick={() => addBuyModal(itemData)}>Купить</PrimaryButton>
          </ItemFooter> */}
        </InfoBlock>
      </Inner>
    </Wrapper>
  }

  // todo loader
  return <Wrapper>
    <GoBackButton />
    <Inner>
      <Plug height={360} width={450} mobile={'max-height: 280px; width: 100%'} />
      <InfoBlock>
        <Plug height={48} />
        <Block marginTop={40} mobileMarginTop={20} />
        <Plug height={48} width={200} />
        <Block marginTop={40} mobileMarginTop={20} />
        <Plug height={18} width={148} />
        <Plug height={18} />
        <Block marginTop={40} mobileMarginTop={20} />
        <Plug height={18} width={148} />
        <Plug height={18} />
        <Block marginTop={40} mobileMarginTop={20} />
        <Plug height={18} width={148} />
        <Plug height={18} />
      </InfoBlock>
    </Inner>
  </Wrapper>
}
