import { makeAutoObservable } from 'mobx'
import decodeJWT, { JwtPayload } from 'jwt-decode'
import { API, ITokenPair } from '../api'

const tokensLocalStorageKey = 'tokenPair'

type WEJWTPayload = {
  id: string,
  name: string,
} & JwtPayload

export default class AuthStore {
  api: API
  isLoggedIn = false
  isWalletAvailable = false
  walletState = {}

  id = ''
  email = ''
  password = ''
  roles: string[] = []

  constructor(api: API) {
    this.api = api

    makeAutoObservable(this)
  }

  async loadUserProfile() {
    const profile = await this.api.getUserProfile()

    this.roles = profile.roles
  }

  createRefresherAxios(tokenPair: ITokenPair, onRefreshFailed: () => void) {
    return this.api.createAxiosWithRefresher(tokenPair, onRefreshFailed)
  }

  signIn(username: string, password: string): Promise<ITokenPair> {
    return this.api.signIn(username, password)
  }

  signUp(username: string, password: string): Promise<ITokenPair> {
    return this.api.signUp(username, password)
  }

  logout(): void {
    this.deleteTokenPair()
    this.setLoggedIn(false)
  }

  loginWithTokenPair(tokenPair: ITokenPair): void {
    const { id, name }: WEJWTPayload = decodeJWT(tokenPair.access_token)
    this.id = id
    this.email = name
    this.writeTokenPair(tokenPair)
  }

  setPassword(password: string): void {
    this.password = password
  }

  setLoggedIn(isLoggedIn: boolean): void {
    this.isLoggedIn = isLoggedIn
  }

  writeTokenPair(tokenPair: ITokenPair): void {
    window.localStorage.setItem(tokensLocalStorageKey, JSON.stringify(tokenPair))
  }

  deleteTokenPair(): void {
    window.localStorage.removeItem(tokensLocalStorageKey)
  }

  readTokenPair(): ITokenPair | null {
    const data = window.localStorage.getItem(tokensLocalStorageKey)
    if (data) {
      try {
        return JSON.parse(data)
      } catch (e) {
        return null
      }
    }
    return null
  }
}
