/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react'
import styled from 'styled-components'
import tick from '../../resources/images/tick.png'
import info from '../../resources/images/info.png'
export const PasswordMinLength = 8
export const PasswordMaxLength = 30

const PasswordErrors = {
  MinLength: 'MinLength',
  Seq: 'Seq',
  RequiredLowerCase: 'RequiredLowerCase',
  RequiredUpperCase: 'RequiredUpperCase',
  RequiredNumber: 'RequiredNumber',
  RequiredSpecialChar: 'RequiredSpecialChar',
  OnlyLatin: 'OnlyLatin',
}

export const getPasswordStrength = (value: string) => {
  const failed = []

  if (!value) {
    return Object.values(PasswordErrors)
  }

  if (value.length < PasswordMinLength || value.length > PasswordMaxLength) {
    failed.push(PasswordErrors.MinLength)
  }
  if (/(.)\1{2,}/.test(value)) {
    failed.push(PasswordErrors.Seq)
  }
  if (!/[a-zа-я]/.test(value)) {
    failed.push(PasswordErrors.RequiredLowerCase)
  }
  if (!/[A-ZА-Я]/.test(value)) {
    failed.push(PasswordErrors.RequiredUpperCase)
  }
  if (!/[0-9]/.test(value)) {
    failed.push(PasswordErrors.RequiredNumber)
  }
  if (!/[^A-Za-z0-9]/.test(value)) {
    failed.push(PasswordErrors.RequiredSpecialChar)
  }
  // eslint-disable-next-line no-control-regex
  if (/[^\x00-\x7FâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ]+/.test(value) || !/[A-Za-z]/.test(value)) {
    failed.push(PasswordErrors.OnlyLatin)
  }
  return failed
}

export const validatePassword = (password: string) => {
  const errors = getPasswordStrength(password)
  return !errors.length
}

const RowContainer = styled.div<{ isPassed?: boolean }>`
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 8px 16px;
  color: ${props => props.isPassed ? props.theme.gray : props.theme.black};
`

const RowText = styled.div`
  margin-left: 16px;
  font-size: 13px;
`

const Symbol = styled.div<{img: string}>`
  width: 16px;
  height: 16px;
  background-image: ${({ img }) => `url(${img})`};
  background-size: contain;
  background-repeat: no-repeat;
`

type IRowProps = {
  isPassed: boolean,
  text: string,
}

const ValidationRow = (props: IRowProps) => {
  const { isPassed, text } = props
  return <RowContainer isPassed={isPassed}>
    {isPassed &&
        <Symbol img={tick} />
    }
    {!isPassed &&
        <Symbol img={info} />
    }
    <RowText>{text}</RowText>
  </RowContainer>
}

const Container = styled.div`
  color: black;
`

export type PasswordRulesProps = {
  password: string,
}

export const PasswordRules = (props: PasswordRulesProps) => {
  const errors = getPasswordStrength(props.password)
  return <Container>
    <ValidationRow isPassed={!errors.includes(PasswordErrors.MinLength)} text={`От ${PasswordMinLength} до ${PasswordMaxLength} символов`} />
    <ValidationRow isPassed={!errors.includes(PasswordErrors.OnlyLatin)} text={'Только латинские буквы'} />
    <ValidationRow isPassed={!errors.includes(PasswordErrors.RequiredLowerCase) && !errors.includes(PasswordErrors.RequiredUpperCase)} text={'В верхнем и нижнем регистрах'} />
    <ValidationRow isPassed={!errors.includes(PasswordErrors.RequiredNumber)} text={'Минимум 1 цифра'} />
    <ValidationRow isPassed={!errors.includes(PasswordErrors.RequiredSpecialChar)} text={'Минимум один специальный символ (@#&%...)'} />
    <ValidationRow isPassed={!errors.includes(PasswordErrors.Seq)} text={'Не более двух одинаковых символов подряд'} />
  </Container>
}