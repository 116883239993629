import testImg from '../../../resources/images/test-img.png'
import testImg2 from '../../../resources/images/test-img2.png'

const items = [
  { id: '0',
    name: 'Абстрактная картина',
    imageUrl: testImg,
    author: 'Николай Смирнов',
    description: 'Длинное описание NFT, для отображения на странице NFT. Пользователь рассказвает о картинке. Пользователь рассказвает о картинке.',
    price: '12000',
    tags: [],
  },
  { id: '1',
    name: 'Абстрактная картина',
    imageUrl: testImg2,
    author: 'Николай Смирнов',
    description: 'Длинное описание NFT, для отображения на странице NFT. Пользователь рассказвает о картинке.',
    price: '12000',
    tags: [],
  },
  { id: '2',
    name: 'Абстрактная картина',
    imageUrl: testImg2,
    author: 'Николай Смирнов',
    description: 'Длинное описание NFT, для отображения на странице NFT. Пользователь рассказвает о картинке.',
    price: '12000',
    tags: [],
  },
  { id: '3',
    name: 'Абстрактная картина',
    imageUrl: testImg,
    author: 'Николай Смирнов',
    description: 'Длинное описание NFT, для отображения на странице NFT. Пользователь рассказвает о картинке.',
    price: '12000',
    tags: [],
  },
  { id: '4',
    name: 'Абстрактная картина',
    imageUrl: testImg,
    author: 'Николай Смирнов',
    description: 'Длинное описание NFT, для отображения на странице NFT. Пользователь рассказвает о картинке.',
    price: '12000',
    tags: [],
  },
  { id: '5',
    name: 'Абстрактная картина',
    imageUrl: testImg2,
    author: 'Николай Смирнов',
    description: 'Длинное описание NFT, для отображения на странице NFT. Пользователь рассказвает о картинке.',
    price: '12000',
    tags: [],
  },
  { id: '6',
    name: 'Абстрактная картина',
    imageUrl: testImg2,
    author: 'Николай Смирнов',
    description: 'Длинное описание NFT, для отображения на странице NFT. Пользователь рассказвает о картинке.',
    price: '12000',
    tags: [],
  },
  { id: '7',
    name: 'Абстрактная картина',
    imageUrl: testImg2,
    author: 'Николай Смирнов',
    description: 'Длинное описание NFT, для отображения на странице NFT. Пользователь рассказвает о картинке.',
    price: '12000',
    tags: [],
  },
  { id: '8',
    name: 'Абстрактная картина',
    imageUrl: testImg,
    author: 'Николай Смирнов',
    description: 'Длинное описание NFT, для отображения на странице NFT. Пользователь рассказвает о картинке.',
    price: '12000',
    tags: [],
  },
  { id: '9',
    name: 'Абстрактная картина',
    imageUrl: testImg2,
    author: 'Николай Смирнов',
    description: 'Длинное описание NFT, для отображения на странице NFT. Пользователь рассказвает о картинке.',
    price: '12000',
    tags: [],
  },
]

export default [
  { id: '0',
    name: 'Абстрактное искусство',
    author: 'Русский музей',
    authorLogo: testImg ,
    previewImg: testImg,
    description: 'Картины современных художников представленных в музее.',
    items,
  },
  { id: '1',
    name: 'Неабстрактное искусство',
    author: 'Русский музей',
    authorLogo: testImg ,
    previewImg: testImg,
    description: 'Картины современных художников представленных в музее.',
    items,
  },
  { id: '2',
    name: 'Абстрактное искусство',
    author: 'Русский музей',
    authorLogo: testImg ,
    previewImg: testImg,
    description: 'Картины современных художников представленных в музее.',
    items,
  },
  { id: '3',
    name: 'Абстрактное искусство',
    author: 'Русский музей',
    authorLogo: testImg ,
    previewImg: testImg,
    description: 'Картины современных художников представленных в музее.',
    items,
  },
]
