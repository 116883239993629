import { useEffect, useState } from 'react'

export const useActiveElement = () => {
  const [activeElement, setActiveElement] = useState(document.activeElement)

  useEffect(() => {
    const onFocus = (event: any) => setActiveElement(event.target)
    const onBlur = () => setActiveElement(null)

    window.addEventListener('focus', onFocus, true)
    window.addEventListener('blur', onBlur, true)

    return () => {
      window.removeEventListener('focus', onFocus)
      window.removeEventListener('blur', onBlur)
    }
  }, [])

  return {
    activeElement,
  }
}