import React from 'react'
import styled from 'styled-components'
import { Block } from '../../../components/Block'
import { PageWrapper, PageTitle } from '../../../components/common/styles'


const Content = styled.div`
  margin-top: 2rem;
  line-height: 1.8;
  font-size: 20px;
`

export const HowItWorks = () => {
  return <PageWrapper>
    <Block marginTop={100} mobileMarginTop={0} />
    <PageTitle>Как это работает</PageTitle>
    <Content>
      <p>NFT или non-fungible token — невзаимозаменяемый токен.
         Это уникальный цифровой актив, созданный и сохранённый на блокчейне.</p>
      <p>Например, каждый рубль можно поменять на любой другой рубль — у них одинаковая ценность.
         Они равнозначны, то есть взаимозаменяемы. NFT друг другу не равны и создаются как уникальные сущности.
         Они могут иметь разное содержимое и отличаться по стоимости.</p>
      <p>NFT могут представлять собой разные цифровые ассеты:
         изображения, аудио и видео, текст, цепочки днк, скины в видео игре и т.д..</p>
      <p>На блокчейне сохраняется информация, подтверждающая уникальность данного NFT и его история.
         Например, запись о создателе, дате выпуска, коллекции, купле-продаже и т.д..
         Эти данные придают NFT особую ценность.
         Таким образом из обычного токена NFT превращается в коллекционный предмет с доказательством владения.
         Как, например, почтовые марки или редкие монеты.</p>
    </Content>
  </PageWrapper>
}