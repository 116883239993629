import { makeAutoObservable } from 'mobx'
import { API } from '../api'

export default class KeysStore {
  api: API

  keyName = 'nft'
  address = ''
  publicKey = ''

  constructor(api: API) {
    this.api = api

    makeAutoObservable(this)
  }

  async init() {
    try {
      const { address, publicKey } = await this.api.getKey(this.keyName)

      this.address = address
      this.publicKey = publicKey
    } catch (e) {
      const { address, publicKey } = await this.api.createKey(this.keyName)

      this.address = address
      this.publicKey = publicKey
    }
  }

  createKey(name: string) {
    return this.api.createKey(name)
  }

  getKey(name: string) {
    return this.api.getKey(name)
  }

  broadcast(tx: any) {
    return this.api.broadcast(tx)
  }
}
