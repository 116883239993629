import styled from 'styled-components'

export const HorisontalDivider = styled.div`
  height: 1px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  margin: 1rem 0;
`

export const VerticalDivider = styled.div`
  border-left: 1px solid rgba(255, 255, 255, 0.2);
`