/* eslint-disable @typescript-eslint/no-unused-vars */
import { createGlobalStyle } from 'styled-components'
import './fonts.css'

const StyleGlobal = createGlobalStyle`
  html {
    box-sizing: border-box;
    background: #0C101C;
    scroll-behavior: smooth;
    overflow-x: hidden;
  }

  #root {
    position: relative;
  }
  
  body {
    margin: 0;
    padding: 0;
    font-size: 14px;
    color: #fff;
    font-family: Montserrat, Helvetica, Arial, sans-serif;
    font-variant: normal;
    position: relative;
    overflow-x: hidden;
    background: #0C101C;
  }

  body.no-scroll,
  html.no-scroll {
    overflow: hidden;
  }

  li {
    list-style: none;
  }
  
  a {
    color: #fff;
    text-decoration: none;
    font-family: Montserrat, Helvetica, Arial, sans-serif;
    cursor: pointer;
  }

  button {
    color: #fff;
    font-family: Montserrat, Helvetica, Arial, sans-serif;
    cursor: pointer;
    border: none;
    background-color: transparent;
  }
  
  ul {
    padding: 0;
  }

  input, textarea {
    border: none;
    font-family: Montserrat, Helvetica, Arial, sans-serif;
    
    :active, :hover, :focus {
      outline: 0;
      outline-offset: 0;
    }

    color: #fff;
  }
`

export default StyleGlobal
