import React from 'react'
import styled from 'styled-components'
import { NavLink } from 'react-router-dom'

type TabItem = {
  route: string,
  label: string,
}

type TabsProps = {
  items: TabItem[],
}

const TabsContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`

const TabsTitle = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
`
const TabsTitleItem = styled(NavLink)`
  font-size: 14px;
  color: white;
  user-select: none;
  transition: opacity 250ms;
  opacity: 0.8;
  font-family: Montserrat, sans-serif;
  font-weight: 400;

  :not(:first-child) {
    margin-left: 16px;
  }

  &.active {
    opacity: 1;
    cursor: default;
  }
`

export const Tabs = (props: TabsProps) => {
  return <TabsContainer>
    <TabsTitle>
      {props.items.map((item, i) => {
        return <TabsTitleItem key={i} to={item.route}>{item.label}</TabsTitleItem>
      })}
    </TabsTitle>
  </TabsContainer>
}